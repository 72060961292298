import { Typography } from "@mui/material"
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { isMobile } from 'react-device-detect'
import { navigate } from "gatsby-link"
import Logo from '../assets/logo_pekoia_horizontal.svg'

import Avatar from '@mui/material/Avatar'
import withStyles from '@mui/styles/withStyles';
import AccessTimeIcon from '@mui/icons-material//AccessTime'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'
import {Amplify} from 'aws-amplify'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import awsConfig from '../aws-exports'

import useStyles, {sxStyle} from '../components/style/pages/survey'

import { addSurvey } from '../templates/fetches/formateurFetch'
import { grey, red } from '@mui/material/colors';






Amplify.configure(awsConfig)

const Survey = ({ data }) => {

    const [eventId, setEventId] = useState(null)
    const [keyMail, setKeyMail] = useState(null)
    const [choice, setChoice] = useState(null)
    const containerRef = React.useRef(null)
    const [checked, setChecked] = useState(0)

    const images = withArtDirection(getImage(data.ogImage), [
        {
            media: "(max-width: 600px)",
            image: getImage(data.smallImage),
        },
    ])


    useEffect(() => {

        const paramsString = window.location.search
        var searchParams = new URLSearchParams(paramsString)

        const eventId = searchParams.get("eventId")
        const keyMail = searchParams.get("key")
        const grade = searchParams.get("choice")

        setEventId(eventId)
        setKeyMail(keyMail)
        setChoice(grade)

        const input = {
            eventId: eventId,
            grade: parseInt(grade),
            question: 'QUE-0',
            key: keyMail
        }

        addSurvey(input)





    }, [])

    const classes = useStyles({
        checked: checked
    })


    const elem = [1, 2, 3].includes(checked) ? surveyData[checked - 1] : null

   


    return (
        <Box width="100vw" height="100vh" >
            
            <GatsbyImage
                image={images}
                alt={''}
                loading="lazy"
                style={{ objectFit: 'cover', width: '100%', height: '100%' }}


            />


            <Box position="absolute" top={0} sx={sxStyle().noImageContainer} >
                <Box bgcolor="black" padding="20px" width="100%" heigth='140px'>
                    <Logo style={{ height: '40px', fill: "white" }} />

                </Box>
                {checked == 0 && <HomeElement setChecked={setChecked} opacity={checked == 0 ? 1 : 0} />}

                {[1, 2, 3].includes(checked) && <SurveyElement eventId={eventId} index={checked} keyMail={keyMail} checked={checked} setChecked={setChecked} key={'survey-' + checked} data={elem} opacity={checked == checked ? 1 : 0} />}

                {checked == 4 && <LastElement opacity={checked == 4 ? 1 : 0} eventId={eventId} keyMail={keyMail} />}


            </Box>


        </Box>
    )
}


const surveyData = [
    {
        question: "Comment jugez-vous l’apport de cet événement sur votre connaissance et votre compréhension du sujet?",
        lowerChoice: "faible",
        higherChoice: "très important"
    },
    {
        question: "Comment jugez-vous le niveau d’expertise de l’intervenant (approche scientifique, documentée, partage d’expériences, citation des sources, démonstrations ...).",
        lowerChoice: "pas du tout expert",
        higherChoice: "expert"
    },
    {
        question: "Est ce que cette intervention  était vivante? (coupures de rythme, participation du public, anectodes ..)",
        lowerChoice: "pas du tout vivante",
        higherChoice: "très vivante"
    }
]

const SurveyElement = (props) => {

    const { data, opacity, keyMail, setChecked, index, eventId, checked } = props


    const [pressed, setPressed] = useState(false)

    const classes = useStyles({ ...props, pressed: pressed })



    const handleClick = (index, key) => {

        const input = {
            eventId: eventId,
            grade: key,
            question: 'QUE-' + index,
            key: keyMail
        }

        addSurvey(input)
        setPressed(index)


        const interval = setTimeout(() => {


            setChecked(index + 1)


        }, 250)

 


    }


    return (<Box position="absolute" top={0} style={{ visibility: opacity == 1 ? 'visible' : 'hidden', opacity: opacity, transition: 'opacity 1s' }} sx={sxStyle(props).surveyContainer} >

        <Box sx={sxStyle(props).questionContainer} marginTop="15%">
            <Typography sx={sxStyle(props).questionStyle}>
                {data.question}
            </Typography>
        </Box>
        <Box sx={sxStyle(props).choiceContainer}>
            <Box sx={sxStyle(props).leftBox}>
                <Typography>
                    {data.lowerChoice}
                </Typography>

            </Box>
            <Box sx={sxStyle(props).rightBox}>

                <Typography>
                    {data.higherChoice}
                </Typography>
            </Box>

        </Box>
        <Box sx={sxStyle(props).numbersBox}>

            {[1, 2, 3, 4, 5].map((key) => (<Box  sx={sxStyle(props).choiceStyle} key={'button-' + index + '-' + key}>
                <Avatar onClick={() => handleClick(index, key)}  sx={sxStyle(props).avatarStyle} >
                <Typography style={{ fontSize: '25px' }} >

                    {key}
                </Typography>

              

                </Avatar>
                </Box>))}
            </Box>
    </Box>)
}

const LastElement = ({opacity, eventId, keyMail}) => {

const classes = useStyles()
const maxSize = 350

const [ended, setEnded] = useState(false)

const handleClick = () => {


const input = {
    eventId: eventId,
key: keyMail,
comment: textAreaValue

}

addSurvey(input)
setEnded(true)
const interval = setTimeout(() => {

    navigate('/')


}, 3000) 

}

const [textAreaValue, setTextAreaValue] = useState('')


return (<Box style={{ visibility: opacity == 1 ? 'visible' : 'hidden', opacity: opacity, transition: 'opacity 1s', width: '100%', height: '100%' }} className="d-flex flex-column align-items-center align-content-center">
    <Box sx={sxStyle().lastElementContainer}>


        <Box sx={sxStyle().lastElementTopSection} >

            <Box marginBottom="20px" >
                <Typography>
                    Souhaitez vous partager un avis supplémentaire ?
                </Typography>
            </Box>
            <textarea style={{ width: '100%', height: '100%', padding: "20px", backgroundColor: "white" }}
                value={textAreaValue}
                onChange={(event) => {
                    if (textAreaValue?.length < maxSize)
                        setTextAreaValue(event.target.value)
                }}
            />

        </Box>
        <Box display="flex" flexDirection="row" justifyContent="end" width="100%" padding="0 10px 0 10px" >

            <Typography style={{ fontSize: '0.8rem' }}>
                {textAreaValue.length}/{maxSize}
            </Typography>
        </Box>
        {ended && <Box bgcolor="black" padding="10px 30px 10px 30px" position="relative" bottom="50px"
            sx={{
                visibility: ended ? 'hidden' : 'visible',
                transition: 'visibility 250ms'

            }}>
            <Typography style={{ color: "white", fontSize: '1.5rem' }}>
                MERCI !
            </Typography>
        </Box>}
        {!ended && <Box sx={sxStyle().buttonContainer}>
            <SurveyButton onClick={handleClick}>
                Envoyer
            </SurveyButton>

        </Box>}

    </Box>


</Box>)
}



const HomeElement = (props) => {


   
    const {setChecked, opacity} = props


    const downHandler = (e) => {
            console.log('@HANDLER')
        console.log(e)
    }
    const upHandler = (e) => {
        if (e.code == 'Enter')
        setChecked(1)
    }


    useEffect(() => {
            window.addEventListener("keydown", downHandler);
        window.addEventListener("keyup", upHandler);
        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener("keydown", downHandler);
        window.removeEventListener("keyup", upHandler);
        };
    }, [])



        const classes = useStyles({...props, isMobile: isMobile })

        console.log('@HOME')
        console.log(sxStyle({...props, isMobile: isMobile }).mainContainer)


        return (<Box sx={sxStyle({...props, isMobile: isMobile }).mainContainer} >
            <Box marginTop="10%">


            </Box>

            <Box sx={sxStyle({...props, isMobile: isMobile }).homeElementText}>
                <Box>
                    <Typography style={{ fontSize: '1.7rem' }}>
                        Merci pour ce retour.
                    </Typography>
                </Box>
                <Box width="100%" marginTop="10px">

                    <Typography style={{ fontSize: '1.7rem', lineHeight: '1.9rem' }}>
                        Accordez nous encore 1 minute  pour nous en dire plus sur la conférence.
                    </Typography>
                </Box>
            </Box>
            <Box marginTop="50px" marginLeft={!isMobile ? "8rem" : "0px"} display="flex" flexDirection="row" alignContent="center" alignItems="center">
                <SurveyButton onClick={() => setChecked(1)}>
                    Donnez votre feed-back
                </SurveyButton>

                {!isMobile && <Box width="150px" display="flex" flexDirection="row">
                    <Box marginLeft="20px">
                        appuyer <span style={{ fontWeight: 600 }}>Enter</span>
                    </Box>
                    <Box>
                        <KeyboardReturnIcon style={{ fontSize: '15px', position: 'relative', left: '2px', bottom: '2px' }} />
                    </Box>
                </Box>}

            </Box>
            <Box bgcolor={grey[700]} marginTop="10px" padding="5px" display="flex" flexDirection="row" alignContent="center" alignItems="center">
                <AccessTimeIcon style={{ color: "white", fontSize: '15px' }} />
                <Box marginLeft="5px">
                    <Typography style={{ color: "white", fontSize: "0.8rem" }}>1 mn</Typography>
                </Box>

            </Box>
        </Box>)
}





export default Survey

export const query = graphql`
query {

    ogImage: imageSharp(original: {src: {regex: "/survey/"}}) {
    gatsbyImageData(
        width: 1000
height: 630
quality: 90
placeholder: BLURRED
transformOptions: {cropFocus: ATTENTION}
)
}

smallImage: imageSharp(original: {src: {regex: "/survey-mobile/"}}) {
    gatsbyImageData(
        placeholder: BLURRED
transformOptions: {cropFocus: ATTENTION}
)
}

}
`

const SurveyButton = withStyles({
    root: {
    width: '250px',
color: 'black',
height: '50px',
borderRadius: '30px',
fontSize: '0.9rem',
backgroundColor: grey[100],

'&:hover': {
    backgroundColor: grey[200]
}
},
label: {
    textTransform: 'none',
},
})(Button)


import { API, graphqlOperation } from "aws-amplify"
import { formateursByFollow } from '../../graphql/queries'
import { createFollows, deleteFollows, createSurvey } from '../../graphql/mutations'

export const fetchFormateurFollowsData = (formateurId, clientId, setIsFollowed) => {

    API.graphql(graphqlOperation(formateursByFollow, { formateurId: formateurId, limit: 100000000 }))
        .then(({ data }) => {

            const follows = data.formateursByFollow.items
            const found = follows.find(follow => follow.clientId == clientId)
            if (found)
            setIsFollowed(found)


        })
        .catch(err => console.log(err))

}

export const addFollows = (clientId, formateurId, setIsFollowed) => {

    API.graphql(graphqlOperation(createFollows, { input: { formateurId: formateurId, clientId: clientId } }))
        .then(({ data }) => {

            const result = data.createFollows
            setIsFollowed(result)
        
        })
        .catch(err => console.log(err))

} 


export const addSurvey = ( input) => {

    API.graphql(graphqlOperation(createSurvey, { input: input }))
        .then(({ data }) => {

        
        
        })
        .catch(err => console.log(err))

}

export const removeFollows = (id) => {

    API.graphql(graphqlOperation(deleteFollows, { input: { id: id } }))
        .then(({ data }) => {

            console.log('@REMOVED')
       
        })
        .catch(err => console.log(err))

}



import makeStyles from '@mui/styles/makeStyles';
import { grey, red } from '@mui/material/colors'

export const sxStyle = (props) => {




    return ({

        lastElementContainer: {
            width: '100%',
            maxWidth: '500px',
            padding: '30px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center',
            marginTop: {
                xs: '100px',
                sm: '100px',
                md: '30px',
                lg: '60px',
                xl: '60px'
            }
        },
        lastElementTopSection: {
            width: "500px",
            maxWidth: '100%',
            height: "300px",
            marginTop: {
                xs: '5%',
                sm: '25%',
                md: '25%',
                lg: '25%',
                xl: '25%'
            }
           
        },
        buttonContainer: {
            marginLeft: "50px",
            marginTop: {
                xs: '55px',
                sm: '60px',
                md: '60px',
                lg: '60px',
                xl: '60px'
            }
        },

        mainContainer: {
            opacity: props?.opacity,

            transition: 'opacity 1s',
            width: '100%',
            height: '100%',
            display: props?.opacity ? "flex" : 'none',
            flexDirection: "column",
            alignItems: 'center',
            alignContent: 'center',
            marginTop: {
                xs: '20%',
                sm: '20%',
                md: '5%',
                lg: '5%',
                xl: '5%'
            }
         
        },
        homeElementText: {
            width: '100%',
            textAlign: 'center',
            marginTop: '25px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center',
            padding: {
                xs: "0px 20px 0 20px",
                sm: "0px 50px 0 50px",
                md: "0px 50px 0 50px",
                lg: "0px 50px 0 50px",
                xl: "0px 50px 0 50px"
            }
          
        },
        noImageContainer: {
            width: '100%', height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center',



        },

        surveyContainer: {
            display:  [1, 2, 3].includes(props?.checked) ? "block" : 'none',


            padding: '0px 20px 0px 20px',
            width: {
                xs: '100%',
                sm: '100%',
                md: '60%',
                lg: '60%',
                xl: '60%'
            },

        },
        questionContainer: {
            height: "140px",
            display: "flex",
            flexDirection: "columnn",
            justifyItems: "start",
            marginTop: {
                xs: '170px',
                sm: '60%',
                md: '30%',
                lg: '30%',
                xl: '30%'
            },
            height: {
                xs: '140px',
                sm: '150px',
                md: '120px',
                lg: '120px',
                xl: '120px'
            }

        },
        questionStyle: {
            fontWeight: 600,
            lineHeight: '1.8rem',
            fontSize: {
                xs: '1.2rem',
                sm: '25px',
                md: '25px',
                lg: '25px',
                xl: '25px'
            }

        },
        choiceContainer: {
            width: "100%",
            marginTop: "50px",
            height: "25px",
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            alignContent: 'center',
            marginTop: '50px'
        },
        leftBox: {
            position: "absolute",
            left: "30px"
        },
        rightBox: {
            position: "absolute",
            right: "30px"
        },
        numbersBox: {
            display: "flex",
            flexDirection: "row",
            justifyItems: 'start',
            justifyContent: 'center',
            marginTop: {
                xs: '10%',
                sm: '10%',
                md: '1%',
                lg: '1%',
                xl: '1%'
            }

        },
        choiceStyle: {
            position: "relative",
            top: "0px",
            marginTop: '0px',
            padding: {
                xs: '5px',
                sm: '20px',
                md: '20px',
                lg: '20px',
                xl: '20px'
            }
        },
        avatarStyle: {
            cursor: 'pointer',
            backgroundColor: grey[500],
            transition: 'background-color 250ms',
            width: '50px',
            height: '50px'
        },
        titleSecondaryText: {
            color: 'white',
            fontSize: {
                xs: '1.5rem',
                sm: '1.5rem',
                md: '2.8rem',
                lg: '2.8rem',
                xl: '2.8rem'
            },
            lineHeight: {
                xs: '1.5rem',
                sm: '1.5rem',
                md: '2.8rem',
                lg: '2.8rem',
                xl: '2.8rem'
            },
            fontWeight: "bold",
            lineHeight: "1.5rem",
            width: '100%'

        }
    })
}






const useStyles = makeStyles((theme) => {

    return ({

        noImageContainer: {
            width: '100%', height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center',



        },
        title: {
            marginTop: '50px',
            fontSize: '60px',
            fontWeight: 600,


            [theme.breakpoints.up('sm')]: {
                fontSize: '100px',
                marginTop: 0,
            }
        },

        homeElementText: {
            width: '100%',
            textAlign: 'center',
            marginTop: '25px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center',
            padding: "0px 20px 0 20px",
            [theme.breakpoints.up('sm')]: {
                padding: "0px 50px 0 50px"

            }

        },
        mainContainer: {
            opacity: props => props.opacity,

            transition: 'opacity 1s',
            width: '100%',
            height: '100%',
            display: props => props.opacity ? "flex" : 'none',
            flexDirection: "column",
            alignItems: 'center',
            alignContent: 'center',
            [theme.breakpoints.up('sm')]: {
                marginTop: '20%'



            },
            [theme.breakpoints.up('md')]: {
                marginTop: '5%'



            }

        },
        surveyContainer: {
            display: props => [1, 2, 3].includes(props.checked) ? "block" : 'none',
            width: "100%",


            padding: '0px 20px 0px 20px',
            [theme.breakpoints.up('sm')]: {

                width: "60%"

            }

        },
        questionContainer: {
            height: "140px",
            marginTop: "170px",
            display: "flex",
            flexDirection: "columnn",
            justifyItems: "start",
            [theme.breakpoints.up('sm')]: {
                marginTop: "60%",
                height: "150px"

            },
            [theme.breakpoints.up('md')]: {

                marginTop: "30%",
                height: "120px"

            }

        },
        questionStyle: {
            fontSize: '1.2rem',
            fontWeight: 600,
            lineHeight: '1.8rem',
            [theme.breakpoints.up('sm')]: {
                fontSize: '25px'

            }

        },
        choiceContainer: {
            width: "100%",
            marginTop: "50px",
            height: "25px",
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            alignContent: 'center',
            marginTop: '50px',


            [theme.breakpoints.up('sm')]: {
                marginTop: '50px'


            }


        },
        leftBox: {
            position: "absolute",
            left: "30px"
        },
        numbersBox: {
            display: "flex",
            flexDirection: "row",
            justifyItems: 'start',
            justifyContent: 'center',
            [theme.breakpoints.up('sm')]: {
                marginTop: '10%'


            },
            [theme.breakpoints.up('md')]: {

                marginTop: '1%'

            },

        }
        ,
        rightBox: {
            position: "absolute",
            right: "30px"
        },
        avatarStyle: {
            cursor: 'pointer',
            backgroundColor: grey[500],
            transition: 'background-color 250ms',
            width: '50px',
            height: '50px'
        },

        choiceStyle: {
            padding: '5px',
            position: "relative",
            top: "0px",
            marginTop: '0px',
            [theme.breakpoints.up('sm')]: {
                padding: '20px'
            }
        },
        lastElementContainer: {
            width: '100%',
            maxWidth: '500px',
            padding: '30px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center',

            [theme.breakpoints.up('sm')]: {
                marginTop: '100px'
            },

            [theme.breakpoints.up('md')]: {
                marginTop: '30px'
            },


            [theme.breakpoints.up('lg')]: {
                marginTop: '60px'
            }


        },
        lastElementTopSection: {
            marginTop: "5%",
            width: "500px",
            maxWidth: '100%',
            height: "300px",
            [theme.breakpoints.up('sm')]: {
                marginTop: "25%"
            }
        },
        buttonContainer: {
            marginTop: "55px",
            marginLeft: "50px",
            [theme.breakpoints.up('sm')]: {
                marginTop: "60px"

            }
        }

    })

})
export default useStyles

